import axios from 'axios';

export async function addUsersFromInviteListToOrgById(organizationId: string, emails: string[]) {
  try {
    console.log('inviteList:', emails);

    const response = await axios.post('/api/organizations/organizationInvitations/addUsersFromInviteListToOrgById', {
      organizationId,
      emails,
    });

    return response.data;
  } catch (error) {
    console.error('Error adding users to organization:', error);
    throw error;
  }
}

export async function createInvitationLinkForOrganization(organization_id: string) {
  try {
    const response = await axios.post(
      '/api/organizations/organizationInvitations/createInvitationLinkForOrganization',
      {
        organization_id,
      },
    );

    return response.data;
  } catch (error) {
    console.error('Error creating invitation link for organization:', error);
    throw error;
  }
}

export async function findOrganizationFromInviteeEmail(email: string) {
  try {
    const response = await axios.post('/api/organizations/organizationInvitations/findOrganizationFromInviteeEmail', {
      email,
    });

    return response.data;
  } catch (error) {
    console.error('Error finding organization from invitee email:', error);
    throw error;
  }
}

export async function getInvitationsToOrgById(organization_id: string) {
  try {
    const response = await axios.post('/api/organizations/organizationInvitations/getInvitationsToOrgById', {
      organization_id,
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching invitations to organization:', error);
    throw error;
  }
}
