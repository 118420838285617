import axios from 'axios';

export async function changeUserPassword(email: string) {
  try {
    const response = await axios.post('/api/users/changeUserPassword', {
      email,
    });

    if (response.data) {
      return response.data;
    }

    console.error('No data in response:', response);

    throw new Error('No data in response');
  } catch (error) {
    console.error('Error changing user password:', error);
    throw error;
  }
}

export async function createUser(email: string, password: string) {
  try {
    const response = await axios.post('/api/users/createUser', {
      email,
      password,
    });

    return response.data;
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
}

export async function deletePendingUserByEmail(email: string) {
  try {
    const response = await axios.post('/api/organizations/organizationMembers/deletePendingUserByEmail', {
      email,
    });

    return response.data;
  } catch (error) {
    console.error('Error deleting pending user by email:', error);
    throw error;
  }
}

export async function deleteUserByUserId(user_id: string) {
  try {
    const response = await axios.post('/api/users/deleteUser', {
      user_id,
    });

    return response.data;
  } catch (error) {
    console.error('Error deleting user by user id:', error);
    throw error;
  }
}

export async function getActiveUsersCount() {
  try {
    const response = await axios.get('/api/users/getActiveUsersCount');

    return response.data;
  } catch (error) {
    console.error('Error fetching active users count:', error);
    throw error;
  }
}

export async function getUserByEmail(email: string) {
  try {
    const response = await axios.post('/api/users/getUserByEmail', {
      email,
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching user by email:', error);
    throw error;
  }
}

export async function getUserByUserId(user_id: string) {
  try {
    const response = await axios.post('/api/users/getUserByUserId', {
      user_id,
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching user by user id:', error);
    throw error;
  }
}

export async function getUserRole(user_id: string) {
  try {
    const response = await axios.post('/api/users/getUserRole', { user_id });

    return response.data;
  } catch (error) {
    console.error('Error fetching user role:', error);
    throw error;
  }
}

export async function getUsers() {
  try {
    const response = await axios.get('/api/users/getUsers');

    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
}

export async function updateUserRole(user_id: string, role_id: string) {
  try {
    // Construct the role_ids array
    const role_ids = [role_id];

    // Make the API call with the formatted role_ids
    const response = await axios.post('/api/users/updateUserRole', {
      user_id,
      role_ids,
    });

    return response.data;
  } catch (error) {
    console.error('Error updating user role:', error);
    throw error;
  }
}
