/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { isAxiosError } from 'axios';
import type { OrganizationData } from '../../interfaces/Organization/Organization';

export async function createOrganizationAndAddUser(organizationName: string, userId: string) {
  try {
    const response = await axios.post('/api/organizations/organization/createOrgAndAddUser', {
      organizationName,
      userId,
    });

    return response.data;
  } catch (error) {
    console.error('Error during organization creation and admin assignment:', error);
    throw error;
  }
}

export async function createNewOrganization(name: string) {
  try {
    const response = await axios.post('/api/organizations/organization/createOrganization', { name });

    return response.data;
  } catch (error) {
    console.error('Error creating new organization:', error);
    throw error;
  }
}

export async function deleteOrganizationByOrgId(organization_id: string) {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        organization_id,
      },
    };

    const response = await axios.delete('/api/organizations/organization/deleteOrganizationByOrgId', config);

    return response.data;
  } catch (error) {
    console.error('Error deleting organization:', error);
    throw error;
  }
}

export async function getOrganizationByName(organizationName: string): Promise<OrganizationData> {
  try {
    const response = await axios.get(
      `/api/organizations/organization/getOrganizationByName?orgName=${organizationName}`,
    );

    // Normalize the response data to match the Organization type
    const organization: OrganizationData = {
      organization: {
        organization_id: response.data.organization.organization_id,
        name: response.data.organization.name,
        display_name: response.data.organization.display_name,
      },
      branding: response.data.branding,
    };

    return organization;
  } catch (error) {
    console.error('Error fetching organization:', error);
    throw error;
  }
}

export async function getOrganizationByUserId(user_id: string): Promise<OrganizationData | null> {
  try {
    const response = await axios.post('/api/organizations/organization/getOrganizationByUserId', { user_id });

    // Check if the response data is valid
    if (!response.data || !Array.isArray(response.data) || response.data.length === 0) {
      console.error('Invalid response data');

      return null;
    }

    // Find the first organization with a valid branding object
    const orgWithBranding = response.data.find(org => org.branding !== null);

    // If no organization with a valid branding object is found, use the first organization in the response data
    const org = orgWithBranding || response.data[0];

    // Normalize the response data to match the Organization type
    const organization: OrganizationData = {
      organization: {
        organization_id: org.organization.organization_id,
        name: org.organization.name,
        display_name: org.organization.display_name,
      },
      branding: org.branding,
    };

    return organization;
  } catch (error) {
    console.error('Error fetching organization:', error);

    // If the error is from axios, provide more detailed error information
    if (isAxiosError(error)) {
      console.error(`Error fetching organization: ${error.response?.status} ${error.response?.data}`);
    }

    return null;
  }
}

export async function getOrganizations(): Promise<OrganizationData[]> {
  try {
    const response = await axios.get('/api/organizations/organization/getOrganizations');

    // Normalize the response data to match the OrganizationData type, safely handling missing parts
    const organizations: OrganizationData[] = response.data.map((org: any) => ({
      organization: {
        organization_id: org.id, // Use "id" instead of "organization.organization_id"
        name: org.name, // Directly use "name"
        display_name: org.display_name, // Directly use "display_name"
      },
      branding: org.branding || null, // Correctly handle optional branding
    }));
    // Optionally filter out entries without an organization

    return organizations;
  } catch (error) {
    console.error('Error fetching organizations:', error);
    throw error;
  }
}

export async function updateOrganizationLogoByOrgId(orgId: string, formData: FormData) {
  try {
    // Note: Axios will automatically set the correct Content-Type header for FormData
    const response = await axios.put('/api/organizations/organization/updateOrganizationLogoByOrgId', formData, {
      params: {
        orgId,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error updating organization logo:', error);
    throw error;
  }
}
