// TypeScript
import axios from 'axios';
import { type CatalogParams, type CreateCatalogApplicationDto } from '../../interfaces/Client/Client';

export async function createCatalogApplication(catalogData: CreateCatalogApplicationDto) {
  try {
    const response = await axios.post('/api/catalog/create', catalogData);

    return response.data;
  } catch (error) {
    console.error('Error creating catalog application:', error);
    throw error;
  }
}

export async function getCatalogApplication(uuid: string) {
  try {
    const response = await axios.get(`/api/catalog/${uuid}`);

    return response.data;
  } catch (error) {
    console.error('Error fetching catalog application:', error);
    throw error;
  }
}

export async function updateCatalogApplication(params: CatalogParams) {
  try {
    const response = await axios.post(`/api/catalog/${params.uuid}`, params.catalogData);

    return response.data;
  } catch (error) {
    console.error('Error updating catalog application:', error);
    throw error;
  }
}

export async function deleteCatalogApplication(uuid: string) {
  try {
    const response = await axios.delete(`/api/catalog/${uuid}`);

    return response.data;
  } catch (error) {
    console.error('Error deleting catalog application:', error);
    throw error;
  }
}

export async function listCatalogApplications() {
  try {
    const response = await axios.get('/api/catalog/list', { params: { enabled: true } });

    return response.data;
  } catch (error) {
    console.error('Error listing catalog applications:', error);
    throw error;
  }
}
