import axios from 'axios';

/**
 * Interface for OrganizationApplication
 */
interface OrganizationApplication {
  applicationId: string;
  customUrl: string;
  enabled: boolean;
  sso: boolean;
}

/**
 * Interface for CreateOrganizationApplicationDto
 */
interface CreateOrganizationApplicationDto extends Partial<OrganizationApplication> {
  organizationId: string;
  applicationId: string;
}

/**
 * Type for UpdateOrganizationApplicationDto
 */
type UpdateOrganizationApplicationDto = Partial<CreateOrganizationApplicationDto>;

/**
 * Function to create an organization application
 * @param createOrganizationApplicationDto - The data to create the organization application
 */
export async function createOrganizationApplication(
  createOrganizationApplicationDto: CreateOrganizationApplicationDto,
) {
  try {
    const response = await axios.post(
      '/api/organizations/organizationApplications/create',
      createOrganizationApplicationDto,
    );

    return response.data;
  } catch (error) {
    console.error('Error creating organization application:', error);
    throw error;
  }
}

/**
 * Function to get an organization application
 * @param organizationId - The ID of the organization
 * @param applicationId - The ID of the application
 */
export async function getOrganizationApplication(organizationId: string, applicationId: string) {
  try {
    const response = await axios.post(`/api/organizations/organizationApplications/get`, {
      organizationId,
      applicationId,
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching organization application:', error);
    throw error;
  }
}

/**
 * Function to list organization applications
 * @param organizationId - The ID of the organization
 */
export async function listOrganizationApplication(organizationId: string) {
  try {
    const response = await axios.post(`/api/organizations/organizationApplications/list`, {
      organizationId,
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching organization applications:', error);
    throw error;
  }
}

/**
 * Function to delete an organization application
 * @param organizationId - The ID of the organization
 * @param applicationId - The ID of the application
 */
export async function deleteOrganizationApplication(organizationId: string, applicationId: string) {
  try {
    const response = await axios.post(`/api/organizations/organizationApplications/delete`, {
      organizationId,
      applicationId,
    });

    return response.data;
  } catch (error) {
    console.error('Error deleting organization application:', error);
    throw error;
  }
}

/**
 * Function to create a client for an organization
 * @param name - The name of the client
 * @param custom_login_page_on - Whether the custom login page is on
 */
export async function createClientForOrganization(name: string, custom_login_page_on: boolean) {
  try {
    const response = await axios.post('/api/organizations/organizationApplications/createClientForOrganization', {
      name,
      custom_login_page_on,
    });

    return response.data;
  } catch (error) {
    console.error('Error creating client for organization:', error);
    throw error;
  }
}

/**
 * Function to update a client
 * @param clientId - The ID of the client
 * @param updateClientDto - The data to update the client
 */
export async function updateSSOClient(clientId: string, samlEndpoint: string, entityId: string, callbackUrl: string, logo_uri: string) {
  try {
    const updateClientDto = {
      custom_login_page_on: true,
      grant_types: [
        'authorization_code',
        'refresh_token',
      ],
      organization_require_behavior: 'pre_login_prompt',
      organization_usage: 'require',
      sso: true,
      sso_disabled: false,
      callbacks: [
        callbackUrl,
      ],
      logo_uri,
      addons: {
        samlp: {
          audience: entityId,
          recipient: samlEndpoint,
          mappings: {
            user_id: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier',
            email: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
          },
          signatureAlgorithm: 'rsa-sha256',
          digestAlgorithm: 'sha256',
          destination: samlEndpoint,
          lifetimeInSeconds: 3600,
          signResponse: false,
          nameIdentifierFormat: 'urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress',
          logout: {
            callback: callbackUrl,
            slo_enabled: true,
          },
          binding: 'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST',
          typedAttributes: true,
          includeAttributeNameFormat: true,
        },
      },
    };

    const response = await axios.post(`/api/organizations/organizationApplications/updateSSOClient`, {
      clientId,
      updateClientDto,
    });

    return response.data;
  } catch (error) {
    console.error('Error updating client:', error);
    throw error;
  }
}

/**
 * Function to update a client's callback URLs
 * @param client_id - The ID of the client
 * @param newCallbackUrl - The new callback URL to add
 */
export async function updateSSOClientCallbacks(client_id: string, newCallbackUrl: string) {
  try {
    // Fetch the current client data
    const getClientResponse = await axios.post(`/api/organizations/organizationApplications/getSSOClientById`, { client_id });
    const currentClientData = getClientResponse.data;

    // Update the callbacks and logout.callback fields
    currentClientData.callbacks = [newCallbackUrl];
    currentClientData.addons.samlp.logout.callback = newCallbackUrl;

    // Remove the properties that should not be included in the updateClientDto
    const { client_id: _, tenant: __, global: ___, client_secret: ____, refresh_token: _____, callback_url_template: ______, signing_keys: _______, ...updateClientDto } = currentClientData;

    // Remove the secret_encoded property from jwt_configuration
    if (updateClientDto.jwt_configuration) {
      const { secret_encoded: ____, ...jwt_configuration } = updateClientDto.jwt_configuration;

      updateClientDto.jwt_configuration = jwt_configuration;
    }

    // Update the client data
    const updateClientResponse = await axios.post(`/api/organizations/organizationApplications/updateSSOClient`, { clientId: client_id, updateClientDto });

    return updateClientResponse.data;
  } catch (error) {
    console.error('Error updating client:', error);
    throw error;
  }
}

/**
 * Function to delete a client
 * @param clientId - The ID of the client
 */
export async function deleteSSOClient(clientId: string) {
  try {
    const response = await axios.delete(`/api/organizations/organizationApplications/deleteSSOClient`, {
      data: { clientId },
    });

    return response.data;
  } catch (error) {
    console.error('Error deleting client:', error);
    throw error;
  }
}

/**
 * Function to update an organization application
 * @param organizationId - The ID of the organization
 * @param applicationId - The ID of the application
 * @param updateOrganizationApplicationDto - The data to update the organization application
 */
export async function updateOrganizationApplication(
  organizationId: string,
  applicationId: string,
  updateOrganizationApplicationDto: UpdateOrganizationApplicationDto,
) {
  try {
    const response = await axios.post(`/api/organizations/organizationApplications/update`, {
      organizationId,
      applicationId,
      ...updateOrganizationApplicationDto,
    });

    return response.data;
  } catch (error) {
    console.error('Error updating organization application:', error);
    throw error;
  }
}

/**
 * Function to add an organization application by URL
 * @param organizationId - The ID of the organization
 * @param urls - The URLs of the applications
 * @param names - The names of the applications
 */
export async function addOrgAppByUrl(organizationId: string, urls: string[], names: string[]) {
  try {
    const response = await axios.post('/api/organizations/organizationApplications/addOrgAppByUrl', {
      organizationId,
      urls,
      names,
    });

    return response.data;
  } catch (error) {
    console.error('Error adding organization application:', error);
    throw error;
  }
}

/**
 * Function to delete an organization application by URL
 * @param organization_id - The ID of the organization
 * @param url - The URL of the application
 */
export async function deleteOrgAppByUrl(organization_id: string, url: string) {
  try {
    const response = await axios.delete(`/api/organizations/organizationApplications/deleteOrgAppByUrl`, {
      data: { organization_id, url },
    });

    return response.data;
  } catch (error) {
    console.error('Error deleting organization application:', error);
    throw error;
  }
}

/**
 * Function to get all client applications by organization ID
 * @param organization_id - The ID of the organization
 */
export async function getAllClientAppsByOrganizationId(organization_id: string) {
  try {
    const response = await axios.post('/api/organizations/organizationApplications/getAllClientAppsByOrganizationId', {
      organization_id,
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching organization applications:', error);
    throw error;
  }
}

/**
 * Function to get an organization application by URL
 * @param organization_id - The ID of the organization
 */
export async function getOrgAppByUrl(organization_id: string) {
  try {
    const response = await axios.get(
      `/api/organizations/organizationApplications/getOrgAppByUrl?organization_id=${organization_id}`,
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching organization application:', error);
    throw error;
  }
}
