import axios from 'axios';
import type { UpdateAppStatusPayload } from '../../pages/api/admin/updateEnabledClientApps';

export async function adminEnabledClients() {
  try {
    const response = await axios.get('/api/admin/adminEnabledClients');

    return response.data;
  } catch (error) {
    console.error('Error fetching enabled clients:', error);
    throw error;
  }
}

export async function adminGetClientsWithoutEnabledFlags() {
  try {
    const response = await axios.get('/api/admin/adminGetClientsWithoutEnabledFlags');

    return response.data;
  } catch (error) {
    console.error('Error fetching clients without enabled flags:', error);
    throw error;
  }
}

export async function getEnabledClientsByOrgId(organization_id: string) {
  try {
    const response = await axios.post('/api/admin/getEnabledClientsByOrgId', {
      organization_id,
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching enabled clients by organization id:', error);
    throw error;
  }
}

export async function getTotalOrgClientsByOrgId(organization_id: string) {
  try {
    const response = await axios.post('/api/admin/getTotalOrgClients', {
      organization_id,
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching total organization clients by organization id:', error);
    throw error;
  }
}

export async function updateEnabledClientsByOrgId(payload: UpdateAppStatusPayload) {
  try {
    const response = await axios.post('/api/admin/updateEnabledClientApps', payload);

    return response.data;
  } catch (error) {
    console.error('Error updating enabled clients by organization id:', error);
    throw error;
  }
}
