import axios from 'axios';

export async function assignAdminRoleToUserByUserId(user_id: string) {
  try {
    const response = await axios.post('/api/roles/assignAdminRoleToUserByUserId', { user_id });

    return response.data;
  } catch (error) {
    console.error('Error assigning admin role to user by user id:', error);
    throw error;
  }
}

export async function assignBasicRoleToUserByUserId(organization_id: string) {
  try {
    const response = await axios.post('/api/roles/assignBasicRoleToUserByUserId', {
      organization_id,
    });

    return response.data;
  } catch (error) {
    console.error('Error assigning basic role to user by user id:', error);
    throw error;
  }
}

export async function assignBasicRoleToUserFromManagement(user_id: string, organization_id: string) {
  try {
    const response = await axios.post('/api/roles/assignBasicRoleToUserFromManagement', {
      user_id,
      organization_id,
    });

    return response.data;
  } catch (error) {
    console.error('Error assigning basic role to user by user id:', error);
    throw error;
  }
}

export async function assignRoleToUserByUserId(userId: string, role: string) {
  try {
    const response = await axios.post('/api/roles/assignRoleToUserByUserId', {
      userId,
      role,
    });

    return response.data;
  } catch (error) {
    console.error('Error assigning role to user by user id:', error);
    throw error;
  }
}

export async function getUsersAssociatedWithRole(role: string) {
  try {
    const response = await axios.post('/api/roles/getUsersAssociatedWithRole', {
      role,
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching users associated with role:', error);
    throw error;
  }
}

export async function removeRoleFromUserByUserId(userId: string, role: string[]) {
  try {
    const response = await axios.post('/api/roles/removeRoleFromUserByUserId', {
      userId,
      role,
    });

    return response.data;
  } catch (error) {
    console.error('Error removing role from user by user id:', error);
    throw error;
  }
}

export async function getUsersAssociatedWithRolesByOrganization(organization_id: string) {
  try {
    const response = await axios.get(
      `/api/roles/getUsersAssociatedWithRolesByOrganization?organization_id=${organization_id}`,
    ); // send organization_id as a query parameter

    return response.data;
  } catch (error) {
    console.error('Error fetching users associated with roles by organization:', error);
    throw error;
  }
}
