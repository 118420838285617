import axios from 'axios';
import * as Sentry from '@sentry/nextjs';

/**
 * Sends an invitation email to a specified email address.
 *
 * @param {string} email - The email address to send the invitation to.
 * @param {string} orgName - The name of the organization sending the invitation.
 * @returns {Promise<AxiosResponse>} The response from the API call.
 * @throws Error and logs it using Sentry if the API call fails.
 */
export const sendInvitationEmail = async (email: string, orgName: string) => {
  try {
    const response = await axios.post('/api/send-grid-api/sendgrid-api', {
      email,
      orgName,
    });

    return response;
  } catch (error) {
    Sentry.captureMessage('Error at sendInvitationEmail');
    Sentry.captureException(error);
    console.log('Error', error);
  }
};
