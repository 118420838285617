import axios from 'axios';

export async function addUserToOrganization(organization_id: string, user_id: string) {
  try {
    const response = await axios.post('/api/organizations/organizationMembers/addUserToOrganization', {
      organization_id,
      user_id,
    });

    return response.data;
  } catch (error) {
    console.error('Error adding user to organization:', error);
    throw error;
  }
}

export async function getMembersWhoBelongToAnOrganization(organization_id: string) {
  try {
    const response = await axios.get('/api/organizations/organizationMembers/getMembersWhoBelongToAnOrganization', {
      params: { organization_id },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching members who belong to an organization:', error);
    throw error;
  }
}

export async function getPendingUserByOrgId(organizationId: string) {
  try {
    const response = await axios.post('/api/organizations/organizationMembers/getPendingUsersByOrgId', {
      organizationId,
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching pending user by organization id:', error);
    throw error;
  }
}
