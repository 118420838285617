import axios from 'axios';
import { API_BASE_URL } from '../../utils/constants';
import { captureMessage, captureException } from '@sentry/nextjs';

/**
 * Fetches the Stripe subscription price ID from the backend.
 * It makes an HTTP GET request to the backend endpoint which returns
 * the price ID needed to create a Stripe subscription.
 * @returns {Promise<{priceId: string} | undefined>}
 * Returns an object containing the price ID if successful, or undefined if an error occurs.
 */
export async function getStripeSubscriptionPriceId(): Promise<{ priceId: string } | undefined> {
    try {
        const response = await axios.get<{ priceId: string }>(`${API_BASE_URL}/stripe/SubscriptionPriceId`);

        return response.data;
    } catch (error) {
        captureMessage('Error fetching Stripe subscription price ID');
        captureException(error);
        console.error('Error fetching Stripe subscription price ID:', error);
    }
}
